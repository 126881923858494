<template>
  <span v-if="section">
    <v-avatar
      v-if="section.type === 'public_transport'"
      :color="`#${section.display_informations.color}`"
      class="white--text font-weight-bold"
      size="40">
      {{section.display_informations.label}}
    </v-avatar>
    <v-btn icon rounded  outlined v-else-if="section.type === 'street_network'" color="primary"  class="mx-1">
      <v-icon color="primary" v-if="section.mode === 'carnopark'">fas fa-car</v-icon>
      <v-icon color="primary" v-else-if="section.mode === 'bss_rent'">fas fa-bicycle</v-icon>
      <v-icon color="primary" size="20" v-else-if="section.mode === 'bike'">fas fa-biking</v-icon>
      <v-icon color="primary" v-else-if="section.mode === 'taxi'">fas fa-taxi</v-icon>
      <v-icon color="primary" v-else>fas fa-walking</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: "ItineraryIconMode",

  props: {
    section: {type: Object, default: () => null}
  }
}
</script>

<style scoped>

</style>